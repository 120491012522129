<template>
    <div id="login">
        <div class="login-main">
            <span class="login-title">账号登录</span>
            <div class="demo-input-suffix">
            <el-input maxlength="50" v-model="account" placeholder="请输入您的账号">
            </el-input>
            <el-input type="password" maxlength="30"  @keyup.enter.native="login" v-model="password" placeholder="请输入账号密码">
            </el-input>
            </div>
            <el-button type="primary" :loading="loading" width="100%" @click="login">登录</el-button>
        </div>
    </div>
</template>
<script>
export default {
    name: "home",
    data() {
        return {
            account: "",
            password: "",
            loading: false
        };
    },
    methods: {
        login() {
            this.loading = true;
            if (!Boolean(String(this.account).length)) {
                this.$message.error("请输入您的账号！");
                return false;
            }
            if (!Boolean(String(this.password).length)) {
                this.$message.error("请输入登录密码！");
                return false;
            }
            this.FetchPost(this.requestUrl.auth.login, {
                username: this.account.trim(),
                password: this.password.trim()
            }).then(res => {
                if (res.code === "0") {
                    localStorage.setItem("_uid",res.data.userId);
                    localStorage.setItem("accountType",res.data.accountType);
                    localStorage.setItem("loginSuccessTimes",res.data.loginSuccessTimes);
                    this.$router.push({
                        name: "botIndex",
                    });
                } else {
                    this.$message.error(res.message);
                }
                this.loading = false;
            });
        }
    }
};
</script>
<style lang="less">
@import "./../../assets/less/login/login.less";
</style>