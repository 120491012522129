<template>
  <div id="dialogues">
    <div
      v-if="showPreview"
      style="
        width: 100vw;
        height: 100vh;
        z-index: 10000;
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.8);
      "
    >
      <div
        @click="showPreview = false"
        style="
          color: white;
          font-size: 30px;
          padding: 30px 30px 15vh;
          text-align: right;
          cursor: pointer;
        "
      >
        X
      </div>
      <img
        style="max-height: 70vh; max-width: 70vw"
        :src="previewImageUrl"
        alt
        srcset
      />
    </div>
    <div v-if="false" class="content-header">
      <div class="leftInfo">
        <span class="headerName">{{ activePageInfo.headerName }}</span>
        <span>
          <el-select
            v-model="activeBot"
            placeholder="请选择机器人"
            @change="selectBot"
          >
            <el-option
              v-for="item in botList"
              :key="item.apiKey"
              :label="item.name"
              :value="item.apiKey"
            ></el-option>
          </el-select>
        </span>
      </div>
    </div>
    <div
      style="padding: 0; height: 100vh"
      v-show="!isEmpty"
      class="dialogues-content"
    >
      <div v-if="false" class="userList">
        <div class="headerName">用户列表</div>
        <div class="headerName" style="padding: 10px">
          <el-input
            class="search-user"
            v-model="searchUserName"
            placeholder="请输入用户名搜索"
            v-on:input="changeInput()"
            @keyup.enter.native="searchUser()"
          >
            <el-button
              @click="searchUser()"
              slot="append"
              icon="el-icon-search"
            ></el-button>
          </el-input>
        </div>
        <div class="nodata" v-if="isNoData">暂无数据</div>
        <div class="userListContent" v-if="!isNoData">
          <!-- 用户信息 -->
          <div
            class="userItem"
            v-for="(item, index) in userList"
            :key="index"
            :class="{ current: activeClass === index }"
          >
            <div
              class="userItemContent"
              @click="
                clickUserli(
                  index,
                  item.destId,
                  item.sourceId,
                  true,
                  null,
                  true,
                  item.channel,
                  item.ext == null ? null : item.ext.agentId
                )
              "
            >
              <div class="userName">
                <span class="name">{{
                  item.source == "MINI_PROGRAM_WEB_SOCKET"
                    ? "小程序用户"
                    : item.sourceName
                }}</span>
                <span class="date">{{
                  new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                }}</span>
              </div>
              <div class="lastMessages">
                <p v-if="item.type == 'RADIO'">
                  {{
                    item.content.split("_")[item.content.split("_").length - 1]
                  }}
                </p>
                <p v-else-if="item.type == 'answer_card_template'">
                  【消息卡片】
                </p>
                <p
                  v-else-if="
                    item.type == 'user_action_to_satisfaction' ||
                    item.type == 'user_action_to_unsatisfactory'
                  "
                >
                  {{ JSON.parse(item.content).input }}
                </p>
                <p
                  v-else-if="
                    item.type != 'START_SESSION' && item.type != 'END_SESSION'
                  "
                >
                  {{ item.content }}
                </p>
              </div>
            </div>
            <el-link
              :underline="false"
              type="primary"
              v-if="index == userList.length - 1 && moreUsers"
              @click="getUserList(activeBot, item.sourceId, false, item.pageNo)"
              >更多...</el-link
            >
          </div>
        </div>
      </div>
      <div class="dialog">
        <div class="headerName">对话记录</div>
        <div
          style="height: calc(100vh - 70px); display: block"
          class="dialogContent"
          id="messageContent"
          ref="msgContainer"
        >
          <div v-for="(item, index) in userMessages" :key="index">
            <el-link
              :underline="false"
              v-if="index == 0 && moreMessages"
              type="primary"
              @click="
                clickUserli(
                  activeClass,
                  userList[activeClass].destId,
                  userList[activeClass].sourceId,
                  false,
                  item.id,
                  false,
                  userList[activeClass].channel,
                  userList[activeClass].ext == null
                    ? null
                    : userList[activeClass].ext.agentId
                )
              "
              >更多消息</el-link
            >
            <el-link
              :underline="false"
              v-if="index == 0 && !moreMessages"
              type="primary"
              disabled
              >没有更多消息了</el-link
            >
            <div
              class="message message-kf"
              v-if="item.source == 'WEB_SOCKET' || item.source == 'PWX_SOCKET' || item.source == 'EKB_MALL' 
              || item.source == 'DING_SOCKET' || item.source == 'WE_CHART_KF_CLIENT' || item.source == 'DINGTALK_GROUP' || item.source == 'DINGTALK_BOT' || item.source == 'APPLET' || item.source == 'FEISHU_SOCKET' || item.source == 'FEISHU_CHAT' || item.source == 'YUNZHIJIA_SOCKET' || item.source == 'ZHIYUAN_SOCKET'"
            >
              <div class="user-detail">
                <div class="media-body">
                  <p
                    v-if="
                      item.type == 'user_action_to_satisfaction' ||
                      item.type == 'user_action_to_unsatisfactory'
                    "
                  >
                    {{ JSON.parse(item.content).input }}
                  </p>
                  <div v-else-if="item.type == 'FORM_SUBMIT'">
                    <FormTemplate :submit="true" :disable="true" :formList="JSON.parse(item.content)">
                    </FormTemplate>
                  </div>
                  <p v-else-if="item.type === 'FEEDBACK'">
                    {{JSON.parse(item.content).feedback.text}}
                  </p>
                  <div v-else-if="item.type == 'VALUE_CONFIRM'">
                    <TextMessage :submit="true" :disable="true" :text="JSON.parse(item.content)">

                    </TextMessage>
                  </div>
                  <div v-else-if="item.type == 'action_assets_card'">
                    <asset-details :data="msg.content"></asset-details>
                  </div>
                  <p v-else-if="item.type == 'TEMPLATE_CARD_BUTTON'"
                  >
                    {{ JSON.parse(item.content).name}}
                  </p>
                  <div v-else-if="item.type == 'VIDEO'">
                    <video-player
                      class="video-player vjs-custom-skin"
                      :options="videoOptions(JSON.parse(item.content))"
                    />
                  </div>
                  <div v-else-if="item.type == 'IMAGE'">
                    <el-image
                      style="width: 35%"
                      :src="JSON.parse(item.content).urls[0]"
                    ></el-image>
                  </div>
                  
                  <div v-else-if="item.type == 'FAST_SELECT'">
                    <p v-html="JSON.parse(item.content).text"></p>
                  </div>
                  <div v-else-if="item.type == 'INTELLIGENT'">
                      <p v-html="JSON.parse(item.content).name"></p>
                  </div>
                  <div v-else-if="item.type == 'RADIO_V2'">
                      <p v-html="JSON.parse(item.content).optionName"></p>
                  </div>

                  <template v-else>
                    <template v-if="item.type == 'action_transfer4pluto'"></template>
                    <p
                      v-else
                      v-html="
                        item.type == 'RADIO'
                          ? item.content.split('_')[3]
                          : item.content
                      "
                    ></p>
                  </template>
                 
                  <span>{{
                    new Date(item.time).Format("yyyy-MM-dd hh:mm:s")
                  }}</span>
                </div>
                <div class="user-image">
                    <img src="./../../assets/images/Female_user_help.png" />
                    <span>用户</span>
                </div>
              </div>
            </div>
            <div
              class="message message-kf"
              v-if="item.source == 'WEBSITE_SOCKET'"
            >
              <div class="user-detail">
                <div class="media-body">
                  <p
                    v-if="
                      item.type == 'user_action_to_satisfaction' ||
                      item.type == 'user_action_to_unsatisfactory'
                    "
                  >
                    {{ JSON.parse(item.content).input }}
                  </p>
                  <div v-else-if="item.type == 'FORM_SUBMIT'">
                    <FormTemplate :submit="true" :disable="true" :formList="JSON.parse(item.content)">
                    </FormTemplate>
                  </div>
                  <p v-else-if="item.type === 'FEEDBACK'">
                    {{JSON.parse(item.content).feedback.text}}
                  </p>
                  <div v-else-if="item.type == 'VALUE_CONFIRM'">
                    <TextMessage :submit="true" :disable="true" :text="JSON.parse(item.content)">
                    </TextMessage>
                  </div>

                  <p v-else-if="item.type == 'TEMPLATE_CARD_BUTTON'"
                  >
                    {{ JSON.parse(item.content).name}}
                  </p>
                  <div v-else-if="item.type==='office_file' ||item.type==='other_file'" class="pre-message">

                    <file-type :urls="JSON.parse(item.content).urls"></file-type>
                  </div>
                  <div v-else-if="item.type == 'VIDEO'">
                    <video-player
                      class="video-player vjs-custom-skin"
                      :options="videoOptions(JSON.parse(item.content).urls[0])"
                    />
                  </div>
                  <div v-else-if="item.type == 'IMAGE'">
                    <el-image
                      style="width: 35%"
                      :src="JSON.parse(item.content).urls[0]"
                    ></el-image>
                  </div>
                  
                  <p
                    v-else
                    v-html="
                      item.type == 'RADIO'
                        ? item.content.split('_')[
                            item.content.split('_').length - 1
                          ]
                        : item.content.replace(/\#[\u4E00-\u9FA5]{1,3}\;/gi, emotion)
                    "
                  ></p>
                 
                  <span>{{
                    new Date(item.time).Format("yyyy-MM-dd hh:mm:s")
                  }}</span>
                </div>
                <div class="user-image">
                    <img src="./../../assets/images/Female_user_help.png" />
                    <span>用户</span>
                </div>
              </div>
            </div>
            <div
              class="message message-kf"
              v-if="item.source == 'MINI_PROGRAM_WEB_SOCKET'"
            >
              <div class="user-detail">
                <div class="media-body">
                  <p
                    v-if="
                      item.type == 'user_action_to_satisfaction' ||
                      item.type == 'user_action_to_unsatisfactory'
                    "
                  >
                    {{ JSON.parse(item.content).input }}
                  </p>
                  <p v-else-if="item.type == 'TEMPLATE_CARD_BUTTON'">
                    {{ JSON.parse(item.content).name }}
                  </p>

                  <p
                    v-else
                    v-html="
                      item.type == 'RADIO'
                        ? item.content.split('_')[
                            item.content.split('_').length - 1
                          ]
                        : item.content
                    "
                  ></p>
                  <span>{{
                    new Date(item.time).Format("yyyy-MM-dd hh:mm:s")
                  }}</span>
                </div>
                <div class="user-image">
                    <!-- <i class="arsenal_icon arsenalyuangong"></i> -->
                    <img src="./../../assets/images/Female_user_help.png" />
                    <span>用户</span>
                </div>
              </div>
            </div>

            <!--  用户满意度满意事件-->
            <div
              class="message"
              v-if="item.type == 'user_action_to_satisfaction'"
            >
              <template>
                <div
                  class="user-detail"
                  v-if="JSON.parse(item.content) != null"
                >
                  <div class="user-image">
                    <!-- <i class="arsenal_icon arsenalyuangong"></i> -->
                    <img src="./../../assets/images/chatboticon.png" />
                    <span>BOT</span>
                  </div>
                 <!-- <div class="media-body">

                    <p v-html="JSON.parse(item.content).reply_text"></p>
                    <span>{{
                      new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                    }}</span>
                    <span v-if="item.source == 'GUORAN_BOT'" class="botname">{{
                      userInfor.botName
                    }}</span>
                    <span
                      v-else-if="item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'"
                      class="botname"
                      >{{ item.sourceId }}</span
                    >
                  </div>-->
                  <div class="media-body-ps"
                       v-if=" JSON.parse(item.content).reply_options != null &&
                        JSON.parse(item.content).reply_options.length > 0">
                    <p class="other" v-html="JSON.parse(item.content).reply_text"></p>
                    <p
                            class="ps"
                            v-if="
                        JSON.parse(item.content).reply_options != null &&
                        JSON.parse(item.content).reply_options.length > 0
                      "
                            v-for="(option, index) in JSON.parse(item.content).reply_options"
                            :key="index"

                    >
                      {{ option.name }}
                    </p>
                    <span style="padding: 0 15px">{{
                      new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                    }}</span>
                    <span v-if="item.source == 'GUORAN_BOT'" class="botname">{{
                      userInfor.botName
                    }}</span>
                    <span
                            v-else-if="item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'"
                            class="botname"
                            style="margin-right: 15px"
                    >{{ item.sourceId }}</span>
                  </div>
                  <div v-else class="media-body">
                    <p v-html="JSON.parse(item.content).reply_text"></p>
                    <span>{{
                        new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                      }}</span>
                    <span v-if="item.source == 'GUORAN_BOT'" class="botname">{{
                        userInfor.botName
                      }}</span>
                    <span
                            v-else-if="item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'"
                            class="botname"
                    >{{ item.sourceId }}</span>
                  </div>

                </div>
              </template>
            </div>

            <!--  用户满意度不满意按钮数据   -->
            <div
              class="message"
              v-if="item.type == 'user_action_to_unsatisfactory'"
            >
              <template>
                <div
                  class="user-detail"
                  v-if="JSON.parse(item.content) != null"
                >
                  <div class="user-image">
                    <!-- <i class="arsenal_icon arsenalyuangong"></i> -->
                    <img src="./../../assets/images/chatboticon.png" />
                    <span>BOT</span>
                  </div>
                  <div class="media-body">
                    <p
                      class="other"
                      v-if="JSON.parse(item.content).reply_text != null"
                    >
                      {{ JSON.parse(item.content).reply_text }}
                    </p>
                    <p
                      class="ps"
                      v-if="
                        JSON.parse(item.content).reply_options != null &&
                        JSON.parse(item.content).reply_options.length > 0
                      "
                      v-for="(option, index) in JSON.parse(item.content)
                        .options"
                      :key="index"
                    >
                      {{ option.name }}
                    </p>
                    <span>{{
                      new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                    }}</span>
                    <span class="botname">{{ userInfor.botName }}</span>
                  </div>
                 <!-- <div class="user-image">
                    <img src="./../../assets/images/Female_user_help.png" />
                    <span>用户</span>
                  </div>-->
                </div>
              </template>
            </div>
            <!-- 人工客服消息 -->
            <template v-if=" item.source === 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER' || item.source == 'AIMER_SERVER'">
              <div class="message">
                <div class="user-detail">
                  <div class="user-image">
                    <template v-if="item.type !== 'action_alert_iframe'">
                      <template v-if="item.source === 'AIMER_SERVER'">
                        <img  :src="item.ext.aimerAgentInfo.avatar" v-if="item.ext && item.ext.aimerAgentInfo && item.ext.aimerAgentInfo.avatar"/>
                        <span v-else-if="(item.ext && item.ext.aimerAgentInfo && item.ext.aimerAgentInfo.name)" class="avat-name">
                        {{item.ext.aimerAgentInfo.name[item.ext.aimerAgentInfo.name.length - 2]}}{{item.ext.aimerAgentInfo.name[item.ext.aimerAgentInfo.name.length - 1]}}
                      </span>
                        <span>{{item.ext && item.ext.aimerAgentInfo ? item.ext.aimerAgentInfo.name :'客服'}}</span>
                      </template>
                      <template v-else>
                        <img alt="人工客服"  src="./../../assets/images/customer_service.png" />
                        <span>客服</span>
                      </template>
                    </template>
                  </div>
                  <div class="media-body">
                    <template v-if="item.type == 'TEXT'">
                      <p v-html="item.content.replace(/\#[\u4E00-\u9FA5]{1,3}\;/gi, emotion)"></p>
                    </template>
                    <template v-else-if="item.type == 'image'">
                      <p>
<!--                        <img  style="width: 100%"
                              :src="item.content"/>-->
                         <el-image
                                 style="width: 35%"
                                 :src="item.content"
                         ></el-image>
                      </p>
                    </template>
                    <template v-else-if="item.type == 'video'">
                      <p>
<!--                        <video :src="item.content"></video>-->
                        <video-player
                                class="video-player vjs-custom-skin"
                                :options="videoOptions({'url':item.content})"
                        />
                      </p>
                    </template>
                    <template v-else-if="item.type === 'office_file' ||item.type === 'other_file'">
                      <p>
                        <file-type :urls="JSON.parse(item.content).urls"></file-type>
                      </p>
                    </template>
                    <!-- 客服邀请评价 -->
                    <template v-else-if="item.type === 'action_alert_iframe' && JSON.parse(item.content).displayStyle === 'dialog'">
                      <div class="service-evaluation">
                        <div class="service-evaluation-l">
                          <span class="service-evaluation-icon">
                            <i class="iconfont guoran-pingjia"></i>
                          </span>
                          <span class="service-evaluation-tips">
                            {{JSON.parse(item.content).description}}
                          </span>
                        </div>
                        <div class="service-evaluation-r">
                          <span>去评价</span>
                        </div>
                      </div>
                    </template>
                    <span>{{
                        new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                      }}</span>
                    <span
                        v-if="item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER' || item.source === 'AIMER_SERVER'"
                        class="botname"
                    >{{ item.sourceName }}</span
                    >
                  </div>
                </div>
              </div>
            </template>
            <div class="massage_event" v-if="item.source == 'SYSTEM_TO_CLIENT' && item.type == 'event'">
              <span v-if="JSON.parse(item.content).type == 'receiver-offline'">
                <div>客服手动结束会话</div>
              </span>
              <span v-else>
                <sender-message-platform :payload="JSON.parse(item.content).payload"></sender-message-platform>
              </span>
            </div>
            <!-- 机器人消息 -->
            <template
              v-if="
                (item.source == 'GUORAN_BOT'
                 ) &&
                item.type != 'user_action_to_satisfaction' &&
                item.type != 'user_action_to_unsatisfactory'
              "
            >
              <div
                class="message"
                v-for="(msg, index) in JSON.parse(item.content)"
                :key="index"
              >
                <div class="user-detail" v-if="msg.content != null && JSON.stringify(msg.content)!='{}' && (msg.content.isGlobal == null || msg.content.isGlobal == false)">
                  <div class="user-image">
                    <img src="./../../assets/images/chatboticon.png" />
                    <span>BOT</span>
                  </div>
                  <div
                    class="media-body"
                    v-if="
                      (msg.type == 'answer_text' ||
                        msg.type == 'answer_welcometext' ||
                        msg.type == 'answer_correct') &&
                      msg.content.list != null &&
                      msg.content.list[0] != '__welcome__'
                    "
                  >
                    <p
                      v-html="
                        msg.content.list != null ? msg.content.list[0] : ''
                      "
                    ></p>
                    <Recocell :item="msg.content"/>
                    <span>{{
                      new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                    }}</span>
                    <span v-if="item.source == 'GUORAN_BOT'" class="botname">{{
                      userInfor.botName
                    }}</span>
                    <span
                      v-else-if="item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'"
                      class="botname"
                      >{{ item.sourceId }}</span
                    >
                  </div>
                  <div
                    class="media-body"
                    style="max-width: 325px"
                    v-else-if="msg.type == 'answer_rich_text'"
                  >
                    <p v-html="msg.content.html"></p>
                    <Recocell :item="msg.content"/>
                    <span>{{
                      new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                    }}</span>
                    <span v-if="item.source == 'GUORAN_BOT'" class="botname">{{
                      userInfor.botName
                    }}</span>
                    <span
                      v-else-if="item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'"
                      class="botname"
                      >{{ item.sourceId }}</span
                    >
                  </div>
                  <div
                    class="media-body"
                    style="max-width: 300px"
                    v-else-if="msg.type == 'action_confirm_value'"
                  >
                    <TextMessage :disable="true" :text="msg.content">
                    </TextMessage>
                    <span>{{
                      new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                    }}</span>
                    <span v-if="item.source == 'GUORAN_BOT'" class="botname">{{
                      userInfor.botName
                    }}</span>
                    <span
                      v-else-if="item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'"
                      class="botname"
                      >{{ item.sourceId }}</span
                    >
                  </div>
                  <div
                    class="media-body"
                    style="max-width: 300px"
                    v-else-if="msg.type == 'action_alert_iframe' && msg.content.displayStyle !== 'dialog'"
                  >
                     <ActionAlert :actionAlertIframe="msg.content"></ActionAlert>
                     <div @click="gotoAlert(msg.content.template)" class="alertUrl">{{msg.content.template}}</div>
                    <span>{{
                      new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                    }}</span>
                    <span v-if="item.source == 'GUORAN_BOT'" class="botname">{{
                      userInfor.botName
                    }}</span>
                    <span
                      v-else-if="item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'"
                      class="botname"
                      >{{ item.sourceId }}</span
                    >
                  </div>
                  <div
                    class="media-body"
                    v-else-if="msg.type == 'action_form'"
                  >
                    <FormTemplate :disable="true" :formList="msg.content">
                    </FormTemplate>
                    <span>{{
                      new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                    }}</span>
                    <span v-if="item.source == 'GUORAN_BOT'" class="botname">{{
                      userInfor.botName
                    }}</span>
                    <span
                      v-else-if="item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'"
                      class="botname"
                      >{{ item.sourceId }}</span
                    >
                  </div>
                  <div
                    class="media-body"
                    style="max-width: 300px"
                    v-else-if="msg.type == 'action_feedback'"
                  >
                    <FeedBack
                      :disable="true"
                      :feedBack="msg.content"
                    ></FeedBack>
                    <span>{{
                      new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                    }}</span>
                    <span v-if="item.source == 'GUORAN_BOT'" class="botname">{{
                      userInfor.botName
                    }}</span>
                    <span
                      v-else-if="item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'"
                      class="botname"
                      >{{ item.sourceId }}</span
                    >
                  </div>

                  <div
                    class="media-body"
                    style="max-width: 300px"
                    v-else-if="msg.type == 'answer_intellect_guide'"
                  >
                    <AiGuide
                      :aiGuide="msg"
                    ></AiGuide>
                    <span>{{
                      new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                    }}</span>
                    <span v-if="item.source == 'GUORAN_BOT'" class="botname">{{
                      userInfor.botName
                    }}</span>
                    <span
                      v-else-if="item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'"
                      class="botname"
                      >{{ item.sourceId }}</span
                    >
                  </div>

                  <div
                    class="media-body"
                    style="max-width: 300px"
                    v-else-if="msg.type == 'answer_card_template'"
                  >
                    <TicketMessage
                      :disable="true"
                      :content="msg.content"
                    >
                    </TicketMessage>
                    <span>{{
                      new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                    }}</span>
                    <span v-if="item.source == 'GUORAN_BOT'" class="botname">{{
                      userInfor.botName
                    }}</span>
                    <span
                      v-else-if="item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'"
                      class="botname"
                      >{{ item.sourceId }}</span
                    >
                  </div>
                  <div
                    class="media-body"
                    style="max-width: 300px"
                    v-else-if="msg.type == 'action_assets_card'"
                  >
                    <asset-details :data="msg.content"></asset-details>
                    <span>{{
                      new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                    }}</span>
                    <span v-if="item.source == 'START_SESSION'" class="botname">{{
                      userInfor.botName
                    }}</span>
                    <span
                      v-else-if="
                        item.source == 'CUSTOMER_SERVICE_SYSTEM' ||
                        item.source == 'WE_CHART_KF_SERVER'
                      "
                      class="botname"
                      >{{ item.sourceId }}</span
                  >
                </div>
                  <div
                    class="media-body"
                    v-else-if="
                      msg.type == 'action_question' &&
                      msg.content != null &&
                      msg.content.questions != null &&
                      msg.content.questions.length > 0
                    "
                  >
                    <p v-html="msg.content.questions[0]"></p>
                    <span>{{
                      new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                    }}</span>
                    <span v-if="item.source == 'GUORAN_BOT'" class="botname">{{
                      userInfor.botName
                    }}</span>
                    <span
                      v-else-if="item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'"
                      class="botname"
                      >{{ item.sourceId }}</span
                    >
                  </div>
                  <div
                    class="media-body"
                    v-else-if="msg.type == 'answer_rich_text_pro'"
                  >
                    <div v-html="msg.content.html"></div>
                    <Recocell :item="msg.content"/>
                    <span>{{
                      new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                    }}</span>
                    <span v-if="item.source == 'GUORAN_BOT'" class="botname">{{
                      userInfor.botName
                    }}</span>
                    <span
                      v-else-if="item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'"
                      class="botname"
                      >{{ item.sourceId }}</span
                    >
                  </div>
                  <div
                    class="media-body"
                    v-else-if="msg.type == 'answer_image'"
                  >
                    <p>
                      <el-image
                        style="width: 35%"
                        :src="msg.content.url"
                      ></el-image>
                    </p>
                    <Recocell :item="msg.content"/>
                    <span>{{
                      new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                    }}</span>
                    <span v-if="item.source == 'GUORAN_BOT'" class="botname">{{
                      userInfor.botName
                    }}</span>
                    <span
                      v-else-if="item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'"
                      class="botname"
                      >{{ item.sourceId }}</span
                    >
                  </div>
                  <div
                    v-else-if="msg.type == 'answer_radio'"
                    class="media-body-ps"
                  >
                    <p class="other">{{ msg.content.description }}</p>
                    <p
                      class="ps"
                      v-for="(option, index) in msg.content.options"
                      :key="index"
                    >
                      {{option.name }}
                    </p>
                    <span style="margin-left: 15px; margin-top: 5px">{{
                      new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                    }}</span>
                    <span v-if="item.source == 'GUORAN_BOT'" class="botname"  style="margin-top: 5px;margin-right: 15px">{{
                      userInfor.botName
                    }}</span>
                    <span
                      v-else-if="item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'"
                      class="botname"

                      >{{ item.sourceId }}</span
                    >
                  </div>
                  <div
                    v-else-if="msg.type == 'action_satisfaction'"
                    class="media-body-ps"
                  >
                    <p class="other">{{ msg.content.satisfactionDes }}</p>
                    <p
                      class="ps"
                      v-for="(option, index) in ['满意', '不满意']"
                      :key="index"
                    >
                      {{ option }}
                    </p>
                    <span style="margin-left: 15px; margin-top: 5px">{{
                      new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                    }}</span>
                    <span v-if="item.source == 'GUORAN_BOT'" class="botname"  style="margin-top: 5px;margin-right: 15px">{{
                      userInfor.botName
                    }}</span>
                    <span
                            v-else-if="item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'"
                            class="botname"

                    >{{ item.sourceId }}</span
                    >
                  </div>

                  <div
                    v-else-if="msg.type == 'answer_video'"
                    class="media-body"
                  >
                    <p>
                      <video-player
                        v-if="msg.type === 'answer_video'"
                        class="video-player vjs-custom-skin"
                        :options="videoOptions(msg.content)"
                      />
                    </p>
                    <Recocell :item="msg.content"/>
                    <span>{{
                      new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                    }}</span>
                    <span v-if="item.source == 'GUORAN_BOT'" class="botname">{{
                      userInfor.botName
                    }}</span>
                    <span
                      v-else-if="item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'"
                      class="botname"
                      >{{ item.sourceId }}</span
                    >
                  </div>
                  <div v-else-if="msg.type == 'action_transfer'">
                    <!--                                        机器人回复为转人工组件内容 -->
                  </div>
                  <div v-else-if="msg.type == 'action_transfer_2_ewechat'">
                    <!--                                        机器人回复为转企业微信客服组件内容 -->
                  </div>
                  <div v-else-if="msg.type ==='action_send_message_to_bot'">
                    <span>{{ msg.content.description }}</span>
                    <span
                            v-for="(cell, index) in msg.content.options"
                            :key="'_' + index"
                            class="link-cell cursor"
                    >{{ cell.name }}</span>
                  </div>
                  <div
                    v-else-if="msg.type == 'action_fast_select' && !msg.content.isGlobal"
                    class="media-body-ps"
                  >
                    <p class="other">【快捷选择】</p>
                    <p
                      class="ps"
                      v-for="(option, index) in msg.content.list"
                      :key="index"
                    >
                      {{option.text }}
                    </p>
                    <span style="margin-left: 15px; margin-top: 5px">{{
                      new Date(item.time).Format("yyyy-MM-dd hh:mm:ss")
                    }}</span>
                    <span v-if="item.source == 'GUORAN_BOT'" class="botname"  style="margin-top: 5px;margin-right: 15px">{{
                      userInfor.botName
                    }}</span>
                    <span
                      v-else-if="item.source == 'CUSTOMER_SERVICE_SYSTEM' || item.source == 'WE_CHART_KF_SERVER'"
                      class="botname"

                      >{{ item.sourceId }}</span
                    >
                  </div>
                  <div v-else>
                    <span v-if="JSON.parse(item.content)[0].type === 'action_transfer4pluto'"></span>
                    <p v-else>{{ msg }}</p>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>

      <div v-if="false" class="userInfo">
        <div class="headerName">用户信息</div>
        <div class="userInfoContent">
          <div class="userBaseInfor">
            <p
              class="icon"
              v-if="userInfor.user == null || userInfor.user.avatar == null"
            >
              <i class="arsenal_icon arsenaluser-circle"></i>
            </p>
            <p
              class="icon"
              v-if="userInfor.user != null && userInfor.user.avatar"
            >
              <el-avatar :src="userInfor.user.avatar"></el-avatar>
            </p>
            <p class="username">
              {{
                userInfor == null || userInfor.user == null
                  ? ""
                  : userInfor.user.userName
              }}
            </p>
            <div class="time">
              <i class="arsenal_icon arsenalclock"></i>
              <span>{{
                userInfor == null
                  ? ""
                  : new Date(userInfor.lastMessageTime).Format(
                      "yyyy-MM-dd hh:mm:ss"
                    )
              }}</span>
            </div>
          </div>
          <!-- 客户访问 -->
          <div class="customerAccess">
            <div class="headerTitle">客户访问</div>
            <div class="itemBot">
              <div class="itemName">所属机器人：</div>
              <div class="itemNContent">{{ userInfor.botName }}</div>
            </div>
            <div class="itemBot">
              <div class="itemName">用户来源：</div>
              <div class="itemNContent">{{ userInfor.userSource }}</div>
            </div>
            <div class="itemBot">
              <div class="itemName">所属应用：</div>
              <div class="itemNContent">{{ userInfor.agentName }}</div>
            </div>
          </div>
          <!-- 客户信息 -->
          <div class="customerAccess">
            <div class="headerTitle">客户信息</div>
            <div class="itemUser">
              <div class="itemName">姓 名：</div>
              <div class="itemNContent">
                {{
                  userInfor == null || userInfor.user == null
                    ? ""
                    : userInfor.user.userName
                }}
              </div>
            </div>
            <div class="itemUser">
              <div class="itemName">性 别：</div>
              <div
                class="itemNContent"
                v-if="
                  userInfor == null || userInfor.user == null
                    ? ''
                    : userInfor.user.gender == 2
                "
              >
                女
              </div>
              <div
                class="itemNContent"
                v-if="
                  userInfor == null || userInfor.user == null
                    ? ''
                    : userInfor.user.gender == 1
                "
              >
                男
              </div>
            </div>
            <div class="itemUser" v-if="accountType !== 'WITHOUT_TEMPLATE'">
              <div class="itemName">手机号：</div>
              <div class="itemNContent">
                {{
                  userInfor == null || userInfor.user == null
                    ? ""
                    : userInfor.user.mobile
                }}
              </div>
            </div>
            <div class="itemUser" v-if="accountType !== 'WITHOUT_TEMPLATE'">
              <div class="itemName">邮 箱：</div>
              <div class="itemNContent">
                {{
                  userInfor == null || userInfor.user == null
                    ? ""
                    : userInfor.user.email
                }}
              </div>
            </div>
            <div class="itemUser">
              <div class="itemName">公 司：</div>
              <div class="itemNContent"></div>
            </div>
            <div class="itemUser" v-if="accountType !== 'WITHOUT_TEMPLATE'">
              <div class="itemName">职 务：</div>
              <div class="itemNContent"></div>
            </div>
            <div class="itemUser" v-if="accountType !== 'WITHOUT_TEMPLATE'">
              <div class="itemName">地 址：</div>
              <div class="itemNContent">
                {{
                  userInfor == null || userInfor.user == null
                    ? ""
                    : userInfor.user.address
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="empty">
      <div v-show="userList.length == 0 && isEmpty" class="empty-rg">
        <div class="image-outer">
          <img class="empty-image" src="../../assets/images/u1016.png" />
        </div>
        <div class="empty-tips">
          <span class="empty-title">当前还没有聊天记录哦</span>
          <span class="empty-warning"
            >如果想要接收聊天消息，请先将机器人接入到您的应用中哦。</span
          >
          <span class="empty-des"
            >您可以在【我的机器人】页面，选择想要接入的机器人，再进入</span
          >
          <span class="empty-des">机器人接入页面完成机器人接入哦。</span>
          <span class="empty-des">
            <el-link type="primary" @click="toBotIndex()"
              >前往我的机器人</el-link
            >
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pageTopHeader from '../../components/page-top-header.vue'
import "video.js/dist/video-js.css";
// import { videoPlayer } from "vue-video-player";
import Emotion from "../../components/Emotion/index";
import FileType from 'askbot-dragon/src/components/FileType'
import TicketMessage from "askbot-dragon/src/components/message/TicketMessage";
import TextMessage from "askbot-dragon/src/components/message/TextMessage";
import FormTemplate from "askbot-dragon/src/components/formTemplate";
import FeedBack from "askbot-dragon/src/components/feedBack";
import ActionAlert from 'askbot-dragon/src/components/ActionAlertIframe';
import Recocell from './../dialogues/component/Recocell';
import AssetDetails from "askbot-dragon/src/components/assetDetails.vue";
import OpenData from "@/components/openData";
import AiGuide from "askbot-dragon/src/components/AiGuide";
import senderMessagePlatform from 'askbot-dragon/src/components/senderMessagePlatform'
window.addEventListener('message', function(messageEvent) {
  var data = messageEvent.data;
  let iframeObj=document.getElementById(data.iframeId);
  if(iframeObj){
    if(data.width){
      iframeObj.width=data.width+'px';
    }
    if(data.height){
      iframeObj.height=data.height+'px';
    }
  }
}, false);
export default {
  name: "dialogues",
  components: {
    AiGuide,
    OpenData,
    Recocell,
    TicketMessage,
    TextMessage,
    FormTemplate,
    FeedBack,
    ActionAlert,
    Emotion,FileType,AssetDetails,pageTopHeader,
    senderMessagePlatform
    
  },
  data() {
    return {
      getDataIng: true,
      showPreview: false,
      previewImageUrl: "",
      activePageInfo: {
        headerName: "对话记录",
      },
      searchUserName: "",
      activeClass: 0,
      activeBot: {},
      currentUser: {},
      botList: [],
      moreMessages: false,
      moreUsers: false,
      apiKey: [],
      userList: [
        // {
        //     sourceName: "",
        //     content: "",
        //     destId: "",
        //     source: "",
        //     sourceId: "",
        //     time: "",
        //     type: "",
        // },
      ],
      userMessages: [],
      userInfor: {
        user: [{ userName: "" }],
      },
      page: 1,
      page_count: "",
      accountType: "NORMAL",
      loginSuccessTimes: "",
      searching: false,
      isEmpty: false,
      isNoData: false,
      lastUserList: [],
      isShowEmj: false,
    };
  },
  methods: {
    gotoAlert(url){
      window.open(url);
    },
    autoLayout() {
      document.documentElement.style.fontSize =
        document.documentElement.clientWidth / 7.5 + "px";
      window.addEventListener(
        "resize",
        function () {
          document.documentElement.style.fontSize =
            document.documentElement.clientWidth / 7.5 + "px";
        },
        false
      );
    },
    previewImage(_this) {
      this.showPreview = true;
      this.previewImageUrl = _this.src;
    },
    videoOptions(videoContent) {
      return {
        muted: true,
        language: "en",
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          {
            type: "video/mp4",
            src: videoContent.url?videoContent.url:videoContent,
          },
        ],
        width: "240",
        height: "160",
      };
    },
    changeInput() {
      if (this.searchUserName == "") {
        this.getUserList(this.activeBot, null, true, null, true);
        this.searching = false;
        this.isNoData = false;
      }
    },
    searchUser() {
      this.searching = true;
      this.isEmpty = false;
      this.getUserList(this.activeBot, null, true, null, true);
    },
    scrollBottom() {
      this.$nextTick(() => {
        let container = document.getElementById("messageContent");
        let scrollHeight = container.scrollHeight;
        container.scrollTop = scrollHeight;
      });
    },
    scrollToTop() {
      this.$nextTick(() => {
        let container = document.getElementById("messageContent");
        container.scrollTop = 0;
      });
    },
    //跳转至机器人页面
    toBotIndex: function () {
      this.$router.push({
        name: "botIndex",
      });
    },
    selectBot(apiKey) {
      this.userList = [];
      this.getUserList(apiKey, null, true, null, true);
    },
    getUserList(apiKey, latestSourceId, showFirstUserInfo, pageNo, clear) {
      const BATCH_USER_LIST_SIZE = 20;
      //const BATCH_USER_LIST_SIZE = 2;
      let url =
        this.requestUrl.messageController.getChatbotMessageList +
        "" +
        apiKey +
        "?" +
        "size=" +
        BATCH_USER_LIST_SIZE;
      if (latestSourceId != null) {
        url = url + "&latestSourceId=" + latestSourceId;
      }
      if (pageNo == null) {
        pageNo = 1;
      }
      url = url + "&pageNo=" + pageNo + "&nameLike=" + this.searchUserName;
      let that = this;
      if (clear == true) {
        this.userList = [];
      }
      this.FetchGet(url).then((res) => {
        if (res.code === "0") {
          if (res.data != null && res.data.length > 0) {
            that.isEmpty = false;
            let userList = res.data;
            // that.userList.push(userList);
            for (
              let i = 0,
                len =
                  userList.length > BATCH_USER_LIST_SIZE
                    ? BATCH_USER_LIST_SIZE
                    : userList.length;
              i < len;
              i++
            ) {
              that.userList.push(userList[i]);
            }
            that.lastUserList = that.userList;
            if (res.data.length > BATCH_USER_LIST_SIZE) {
              that.moreUsers = true;
            } else {
              that.moreUsers = false;
            }
            if (showFirstUserInfo != null && showFirstUserInfo == true) {
              let firstUser = that.userList[0];
              // that.clickUserli(
              //   0,
              //   firstUser.destId,
              //   firstUser.sourceId,
              //   true,
              //   null,
              //   true,
              //   firstUser.channel,
              //   firstUser.ext == null ? null : firstUser.ext.agentId
              // );
            }
          } else if (res.data.length == 0 && that.searching == true) {
            that.userList = that.lastUserList;
            that.isEmpty = false;
            that.isNoData = true;
          } else if (res.data.length == 0 && !that.searching) {
            that.isEmpty = true;
          }
        } else {
          this.$message.error(res.message);
        }
        this.loading = true;
      });
    },
    // 获取机器人列表
    getBotList() {
      this.FetchGet(this.requestUrl.bot.getBotList, {
        page: 1,
        size: 100,
      }).then((res) => {
        if (res.code === "0") {
          this.botList = res.data.list;
          this.activeBot = this.botList[0].apiKey;
          this.getUserList(this.activeBot, null, true, null, true);
        } else {
          this.$message.error(res.message);
        }
        this.loading = false;
      });
    },
    //点击用户列表
    clickUserli(
      index,
      destId,
      sourceId,
      scroll,
      lastMessageId,
      clearHistory,
      channel,
      agentId
    ) {
      this.activeClass = index;
      if (clearHistory != null && clearHistory == true) {
        this.userMessages = [];
      }
      let url =
        this.requestUrl.messageController.getUserDialog +
        "" +
        destId +
        "/" +
        sourceId +
        "?size=11";
      //let url = this.requestUrl.messageController.getUserDialog+ "" +destId + "/" +sourceId;
      if (lastMessageId != null) {
        url = url + "&lastMessageId=" + lastMessageId;
      }
      let that = this;
      this.FetchGet(url).then((res) => {
        if (res.code === "0") {
          let userMessages = res.data;
          //that.userMessages = res.data;
          if (userMessages != null) {
            if (userMessages.length > 10) {
              let slice = userMessages.slice(0, 10);
              for (let i = userMessages.length - 1; i >= 0; i--) {
                if (userMessages[i].type == null) {
                  let tampArr = JSON.parse(userMessages[i].content);
                  if (tampArr != null) {
                    tampArr.forEach((cell) => {
                      if (cell.type == "answer_rich_text_pro") {
                        cell.content.html = cell.content.html.replace(
                          /onclick="__rich_text_pro_button_onclick\(this\)"/g,
                          ""
                        );
                      }
                    });
                  }
                  userMessages[i].content = JSON.stringify(tampArr);
                }
                that.userMessages.unshift(userMessages[i]);
              }
              // that.userMessages = ;
              that.moreMessages = true;
            } else {
              for (let i = userMessages.length - 1; i >= 0; i--) {
                that.userMessages.unshift(userMessages[i]);
              }
              that.moreMessages = false;
            }
          }
          //切换用户时定位到记录顶部
          if (scroll) {
            //that.scrollBottom();
            setTimeout(function () {
              that.scrollBottom();
            }, "1000");
            //that.scrollToTop();
          }
        } else {
          this.$message.error(res.message);
        }
        this.getDataIng = false;
        this.loading = true;
      });
      // this.getUserInformation(this.activeBot, sourceId, channel, agentId);
    },
    //获取用户详细对话
    getUserDialog() {},
    //获取用户详细对话
    getUserInformation(destId, sourceId, channel, agentId) {
      let url =
        this.requestUrl.messageController.getUserInformation +
        "" +
        destId +
        "/" +
        sourceId;
      if (channel != null) {
        url = url + "?channel=" + channel;
        if (agentId != null) {
          url = url + "&agentId=" + agentId;
        }
      } else {
        if (agentId != null) {
          url = url + "?agentId=" + agentId;
        }
      }
      this.FetchGet(url).then((res) => {
        if (res.code === "0") {
          this.userInfor = res.data;
        } else {
          this.$message.error(res.message);
        }
        this.loading = true;
      });
    },
    // 将匹配结果替换表情图片
		emotion (res) {
		  let word = res.replace(/\#|\;/gi,'')
		  const list = ['微笑', '撇嘴', '色', '发呆', '得意', '流泪', '害羞', '闭嘴', '睡', '大哭', '尴尬', '发怒', '调皮', '呲牙', '惊讶', '难过', '酷', '冷汗', '抓狂', '吐', '偷笑', '可爱', '白眼', '傲慢', '饥饿', '困', '惊恐', '流汗', '憨笑', '大兵', '奋斗', '咒骂', '疑问', '嘘', '晕', '折磨', '衰', '骷髅', '敲打', '再见', '擦汗', '抠鼻', '鼓掌', '糗大了', '坏笑', '左哼哼', '右哼哼', '哈欠', '鄙视', '委屈', '快哭了', '阴险', '亲亲', '吓', '可怜', '菜刀', '西瓜', '啤酒', '篮球', '乒乓', '咖啡', '饭', '猪头', '玫瑰', '凋谢', '示爱', '爱心', '心碎', '蛋糕', '闪电', '炸弹', '刀', '足球', '瓢虫', '便便', '月亮', '太阳', '礼物', '拥抱', '强', '弱', '握手', '胜利', '抱拳', '勾引', '拳头', '差劲', '爱你', '不', '可以', '爱情', '飞吻', '跳跳', '发抖', '怄火', '转圈', '磕头', '回头', '跳绳', '挥手', '激动', '街舞', '献吻', '左太极', '右太极']
		  let index = list.indexOf(word)
		  return `<img height="20px" src="https://guoranim.oss-cn-beijing.aliyuncs.com/emoji/${index+1}.png" align="middle">`
		},
		//表情
		showEmoji(){
			this.isShowEmj = !this.isShowEmj;
		},
		handleEmotion (i) {
		  this.contentText += i
		},
  },
  mounted() {
    this.autoLayout();
    window.previewImage = this.previewImage;
    // this.getBotList();

    // this.getUserList(this.$route.query.destId, null, true, null, true);
    this.accountType = localStorage.getItem("accountType");
    //this.getChatbotMessageList();

    // destId=ce506d4ff6c741459e34c9ba9f83ae24&sourceId=wwac60444bf20f31b1_JiuLi
    // http://localhost:8081/#/tbchat?destId=bf76bf60cb9b4451ad4d83b8f528d498&sourceId=wwac60444bf20f31b1_JiuLi
    let destId = this.$route.query.destId;
    let sourceId = this.$route.query.sourceId;
    this.userList.push({
      destId: destId,
      sourceId: sourceId,
    });
    this.clickUserli(0, destId, sourceId, true, null, false, "", "");
  },
};
</script>

<style lang="less" scoped>
@import "./../../assets/less/dialogues/tbChat.less";
.leftInfo {
  display: flex !important;
  justify-content: space-between !important;
}
.alertUrl {
  word-break: break-all;
  margin-top: 8px;
  color: blue;
  cursor: pointer;
}
.vjs-control-bar {
  .vjs-control {
    width: 30px;
    height: 30px;
  }
}
.emotion-container{
	position: absolute;
	bottom: 100px;
	width: 100%;
	background-color: #FFFFFF;
}
/deep/ figure {
  margin-inline-start:0px
}
</style>
